import "./styles.scss";
import React from "react";

import SideHeader from "components/SideHeader";
import LocationMap from "components/LocationMap";

const mainClass = "home-map";

const Map = ({ markers }) => {
  return (
    <section className={mainClass}>
      <LocationMap
        latitude={51.9189046}
        longitude={19.1343786}
        zoom={6}
        markers={markers}
      />
    </section>
  );
};

export default Map;
