import "./styles.scss";

import React from "react";
import classnames from "classnames";

import Button from "../Button";
import InvestmentGridItem from "../InvestmentGridItem";
import ItemsSlider from "../ItemsSlider";

import investmentTileParser from "utils/investmentTileParser";

import ListIcon from "icons/list.svg";

const mainClass = "investments_slider";

const InvestmentsSlider = ({
  data,
  theme,
  header_data,
  description,
  popup_form,
}) => {
  const investments_data = investmentTileParser(data);

  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--${theme?.header_color}`]: !!theme?.header_color,
      })}
    >
      <div className="container">
        {!!header_data && (
          <div
            className={classnames(`${mainClass}__header`, {
              [`${mainClass}__header--${theme?.header_color}`]:
                !!theme?.header_color,
            })}
          >
            <div className={`${mainClass}__header__title`}>
              <div
                className={classnames(`${mainClass}__header__title__icon`, {
                  [`${mainClass}__header__title__icon--${theme?.header_color}`]:
                    !!theme?.header_color,
                })}
              >
                {header_data?.icon}
              </div>
              <h3>
                <span>Inwestycje</span> {header_data?.location}
              </h3>
            </div>
            <Button
              icon={<ListIcon />}
              href={header_data?.href}
              variant={theme?.header_color}
            >
              Pełna oferta {header_data?.location}
            </Button>
          </div>
        )}
        <ItemsSlider theme={theme}>
          {investments_data.map((props, index) => (
            <div key={index} className={`${mainClass}__slide`}>
              <InvestmentGridItem
                {...props}
                description={description}
                variant={theme?.items_theme}
                popup_form={popup_form}
              />
            </div>
          ))}
        </ItemsSlider>
      </div>
    </div>
  );
};

export default InvestmentsSlider;
