import "./styles.scss";

import React from "react";
import Slider from "react-slick";

import SearchBar from "components/SearchBar";
import SideHeader from "components/SideHeader";

import ArrowIcon from "icons/slider-arrow.svg";

const mainClass = "home-hero";

function CustomArrow(props) {
  const { className, onClick } = props;
  return <ArrowIcon className={className} onClick={onClick} />;
}

const Hero = ({ show_searcher, promotion_mode, slider }) => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 2000,
    useTransform: false,
    nextArrow: (
      <CustomArrow className="home-hero__arrow home-hero__arrow--next" />
    ),
    prevArrow: (
      <CustomArrow className="home-hero__arrow home-hero__arrow--prev" />
    ),
  };

  return (
    <section className={mainClass}>
      <div className="container">
        {!!show_searcher && <SearchBar pathOnSubmit={`/investments`} />}
        {!!promotion_mode && <h1>Promocje</h1>}
      </div>
      <Slider {...sliderSettings}>
        {slider?.map(({ slideTitle, slideText, slideImage }, index) => (
          <div key={index}>
            <div
              className={`${mainClass}__slide`}
              style={{ backgroundImage: `url(${slideImage.sourceUrl})` }}
            >
              {!!!promotion_mode && (
                <SideHeader header={slideTitle} text={slideText} />
              )}
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default Hero;
