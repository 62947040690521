import "./styles.scss";
import React from "react";

import FacilitiesGrid from "components/FacilitiesGrid";

import CPinIcon from "icons/facilities/c-pin.svg";
import RateReturnIcon from "icons/facilities/rate-return.svg";
import SofaIcon from "icons/facilities/sofa.svg";
import DivisionIcon from "icons/facilities/division.svg";
import BuildingIcon from "icons/facilities/building.svg";
import BrandsIcon from "icons/facilities/brands.svg";
import WalletIcon from "icons/facilities/wallet.svg";

const invest_facilities_data = [
  {
    icon: <CPinIcon />,
    title: "Inwestycje | Premium",
    text: "Wyselekcjonowane projekty w topowych lokalizacjach",
  },
  {
    icon: <RateReturnIcon />,
    title: "Stała stopa | zwrotu",
    text: "Comiesięczny czynsz przez cały okres umowy najmu",
  },
  {
    icon: <SofaIcon />,
    title: "Second | Home",
    text: "Atrakcyjne apartamenty na użytek własny, bez umowy najmu",
  },
  {
    icon: <DivisionIcon />,
    title: "Podział | zysków",
    text: "Oferty z umową podziałową – 70% zysku dla właściciela apartamentu",
  },
  {
    icon: <BuildingIcon />,
    title: "Oferty | Off Market",
    text: "Unikalne projekty wyłącznie w ofercie condo.pl",
  },
  {
    icon: <BrandsIcon />,
    title: "Znane | marki",
    text: "Międzynarodowe i polskie mark: Radisson, Mercure, Aries, voco by IHG",
  },
  {
    icon: <WalletIcon />,
    title: "Najlepsze | ceny",
    text: "Gwarancja najlepszej ceny condo.pl",
  },
];

const mainClass = "home-invest";

const Invest = () => {
  return (
    <section className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__header`}>
          <h3>
            Inwestuj w nieruchomości
            <br />
            <span>z condo.pl</span>
          </h3>
        </div>
        <FacilitiesGrid data={invest_facilities_data} />
      </div>
    </section>
  );
};

export default Invest;
