import "./styles.scss";
import React from "react";

import Button from "components/Button";
import SideHeader from "components/SideHeader";
import InvestmentsSlider from "components/InvestmentsSlider";

import ListIcon from "icons/list.svg";

// import SunnyIcon from "icons/sunny.svg";

const mainClass = "home-apartments";

const Apartments = ({ data }) => {
  return (
    <section className={mainClass}>
      <div className="container">
        <SideHeader
          header="Atrakcyjne"
          text="apartamenty inwestycyjne | i Second Home"
        />
        <InvestmentsSlider data={data} description popup_form />
        <div className={`${mainClass}__action`}>
          <Button variant="white" icon={<ListIcon />} href="/investments/">
            Wszystkie inwestycje
          </Button>
        </div>
        <SideHeader header="Sprawdź" text="gdzie warto | zainwestować" />
      </div>
    </section>
  );
};

export default Apartments;
